import { Injectable } from '@angular/core';
import { RoutePath } from '../../shared/enums/routes.enum';
import { NGXLogger } from 'ngx-logger';
import { QueryStringService } from '../../core/services/query-string.service';
import { AppointmentDetailsService } from '../../core/services/appointment-details.service';
import { AppConfigService } from '../../core/services/app-config.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { JsonContentService } from './json-content.service';

/**
 * This service is used to manage the navigation sequence for the scheduling process.
 */
@Injectable({
  providedIn: 'root',
})
export class NavSchedulingService {
  steps: string[] = [];
  startPath: RoutePath | null = null;

  constructor(
    private logger: NGXLogger,
    private appConfigService: AppConfigService,
    private queryStringService: QueryStringService,
    private appointmentDetailsService: AppointmentDetailsService,
    private localStorageService: LocalStorageService,
    private jsonContentService: JsonContentService,
  ) {
    this.initNavigationSequence();
  }

  getFirstRoute(): string {
    if (this.startPath) {
      // find the index of the startPath
      const index = this.steps.indexOf(this.startPath);
      if (index !== -1) {
        return this.steps[index];
      }
    }
    return this.steps[0];
  }

  getRouteIndex(route: string): number {
    return this.steps.indexOf(route);
  }

  getNextRoute(currentRoute: string): string {
    const steps = this.steps;
    const currentIndex = steps.indexOf(currentRoute);

    if (currentIndex === -1) {
      this.logger.error(`Invalid route: ${currentRoute}`);
      return RoutePath.LANDING_PAGE;
    }

    if (currentIndex === steps.length - 1) {
      return RoutePath.CONFIRMATION_VIEW;
    }

    this.logger.debug(
      'getNextRoute currentIndex',
      currentIndex,
      steps[currentIndex + 1],
    );
    return steps[currentIndex + 1];
  }

  getPreviousRoute(currentRoute: string): string {
    const currentIndex = this.steps.indexOf(currentRoute);

    if (currentIndex === -1) {
      this.logger.error(`Invalid route: ${currentRoute}`);
      return RoutePath.LANDING_PAGE;
    }

    if (currentIndex === 0) {
      return this.steps[0];
    }

    if (this.steps[currentIndex - 1] === RoutePath.LOCATION_LAYOUT) {
      // If we're letting them go back to the location page, then make sure we're not
      // keeping a location in storage.
      this.localStorageService.removeItem('loc');
    }
    this.logger.debug(
      '### getPreviousRoute currentIndex',
      currentIndex,
      this.steps[currentIndex - 1],
    );
    return this.steps[currentIndex - 1];
  }

  removeStep(step: string): void {
    this.steps.splice(this.steps.indexOf(step), 1);
  }

  /* istanbul ignore next */
  dumpSteps(): void {
    this.logger.debug(this.steps);
  }

  initNavigationSequence(): void {
    this.steps = this.populateNavigationSequence();
  }

  private populateNavigationSequence(): string[] {
    const steps: string[] = [];

    steps.push(RoutePath.LANDING_PAGE);
    steps.push(RoutePath.SITE_MESSAGE);
    steps.push(RoutePath.LOCATION_LAYOUT);
    steps.push(RoutePath.SERVICE_VIEW);
    steps.push(RoutePath.CONFIRM_DATE_TIME);
    steps.push(RoutePath.PATIENT_INFO);
    steps.push(RoutePath.CONFIRMATION_VIEW);

    let index = -1;
    if (this.hasPreselectedLocation()) {
      if (this.hasPreselectedLocationLocked()) {
        index = steps.indexOf(RoutePath.LANDING_PAGE);
        steps.splice(index, 1);
        index = steps.indexOf(RoutePath.LOCATION_LAYOUT);
        steps.splice(index, 1);
      } else {
        this.startPath = RoutePath.SERVICE_VIEW;
      }
    } else if (this.skipLandingPage()) {
      index = steps.indexOf(RoutePath.LANDING_PAGE);
      steps.splice(index, 1);
    } else if (this.checkForNavToLocations()) {
      index = steps.indexOf(RoutePath.LANDING_PAGE);
      steps.splice(index, 1);
    }

    if (this.appointmentDetailsService.isSingleLocation) {
      // Don't navigate to location layout if there's only one location; set it, though.
      index = steps.indexOf(RoutePath.LOCATION_LAYOUT);
      steps.splice(index, 1);
      this.appointmentDetailsService.setLocation(
        this.appConfigService.getScheduler().offices[0],
      );
    }

    if (
      !this.jsonContentService.isMessageValid(
        JsonContentService.content.SITE_MESSAGE,
      )
    ) {
      index = steps.indexOf(RoutePath.SITE_MESSAGE);
      steps.splice(index, 1);
    }

    return steps;
  }

  populateNavigationSequenceReschedule(): void {
    const steps: string[] = [];

    steps.push(RoutePath.UPCOMING);
    steps.push(RoutePath.CONFIRM_DATE_TIME);
    steps.push(RoutePath.UPCOMING);

    this.steps = steps;
  }

  private hasPreselectedLocation(): boolean {
    const preselectedLocation =
      this.queryStringService.getPreSelectedLocation();
    if (preselectedLocation) {
      this.logger.debug('Preselected location', preselectedLocation);
      this.appointmentDetailsService.setLocation(preselectedLocation.office);
      this.appointmentDetailsService.isLocationPreselected = true;
      this.appointmentDetailsService.isLocationLocked =
        preselectedLocation.isLocked;
      return true;
    }
    return false;
  }

  private hasPreselectedLocationLocked(): boolean {
    if (this.hasPreselectedLocation()) {
      return this.appointmentDetailsService.isLocationLocked;
    }
    return false;
  }

  private skipLandingPage(): boolean {
    const portalObj = this.appConfigService.getPortal();
    return (
      portalObj.scheduler &&
      !portalObj.practiceCancelReschedule &&
      !portalObj.featureForms &&
      !this.hasExtraQuickLinks()
    );
  }

  private hasExtraQuickLinks(): boolean {
    const portalObj = this.appConfigService.getPortal();
    if (
      !portalObj.extraQuickLinks ||
      portalObj.extraQuickLinks === '' ||
      portalObj.extraQuickLinks.length === 1
    ) {
      return false;
    }
    try {
      // Quick check for validity
      JSON.parse(portalObj.extraQuickLinks);
      return true;
    } catch (error) {
      this.logger.error(
        'Error in handleExtraQuickLinkClick. Check that the data is formatted correctly.',
      );
      return false;
    }
  }

  private checkForNavToLocations(): boolean {
    return this.queryStringService.getNavToLocations();
  }
}
