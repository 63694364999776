import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Office } from '../../shared/domains/scheduler/office/office';
import { Translations } from 'src/app/core/services/translations.service';
import { NavService } from 'src/app/core/services/nav.service';
import { AppConfigService } from '../../core/services/app-config.service';
import { AppointmentDetailsService } from '../../core/services/appointment-details.service';
import { ILanguageModel } from '../../shared/model/ilanguage-model';
import { MatButton } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';
import { RoutePath } from '../../shared/enums/routes.enum';
import { NavSchedulingService } from '../../scheduler/services/nav-scheduling.service';

/**
 * This component allows the user to select a location from a list of available locations.
 */
@Component({
  selector: 'eos-location-layout',
  templateUrl: './location-layout.component.html',
  styleUrls: ['./location-layout.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, MatButton, TranslateModule],
})
export class LocationLayoutComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  sub = new Subscription();
  officeList: Office[] = [];
  locationName = '';
  layoutPageLanguage: ILanguageModel['locationLayout'];
  serviceViewPageLanguage: ILanguageModel['serviceView'];

  constructor(
    private logger: NGXLogger,
    private appointmentDetailsService: AppointmentDetailsService,
    private route: ActivatedRoute,
    private translations: Translations,
    private navService: NavService,
    private appConfigService: AppConfigService,
    private translateService: TranslateService,
    private navSchedulingService: NavSchedulingService,
  ) {}

  ngOnInit(): void {
    this.layoutPageLanguage = this.translations.locationLayout;
    this.serviceViewPageLanguage = this.translations.serviceView;
    this.setViewTitle();
    this.buildOfficeList();
    // clear previous selections
    this.appointmentDetailsService.setInsuranceDVO(null);

    this.sub.add(
      this.route.queryParams.subscribe((params) => {
        this.locationName = params['loc'];
      }),
    );
  }

  setViewTitle(): void {
    const translatedTitle: string = this.translateService.instant(
      this.layoutPageLanguage.selectLocation,
    );
    this.appConfigService.setViewTitle(translatedTitle);
  }

  hideBackButton(): boolean {
    return (
      // If the current route is first in the steps, there's nowhere
      // to go back to so hide the back button.
      this.navSchedulingService.getRouteIndex(RoutePath.LOCATION_LAYOUT) === 0
    );
  }

  buildOfficeList(): void {
    this.officeList = [];
    const schedulerData = this.appConfigService.getScheduler();
    if (schedulerData.offices) {
      this.officeList.push(...schedulerData.offices);
    }
    this.logger.debug('buildOfficeList', this.officeList);
  }

  selectLocation(office: Office): void {
    this.logger.debug('selected Location', office);

    this.appointmentDetailsService.setLocation(office);

    this.navService.navigateToPage(
      this.navSchedulingService.getNextRoute(RoutePath.LOCATION_LAYOUT),
    );
  }

  handleKeydown(event: KeyboardEvent, office: Office): void {
    if (event.key === 'Enter') {
      this.selectLocation(office);
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
      this.logger.info('Unsubscribes from all subscriptions');
    }
  }

  goToHomePage(): void {
    this.navService.navigateToPage(
      this.navSchedulingService.getPreviousRoute(RoutePath.LOCATION_LAYOUT),
    );
  }
}
